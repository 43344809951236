import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import AppBar from "@mui/material/AppBar";
import SearchBox from "../../common/components/SearchBox";
import { useTheme } from "@mui/material/styles";
import {
  Typography,
  Box,
  CardContent,
  Toolbar,
  IconButton,
  Badge,
  Divider,
  Menu,
  MenuItem,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Checkbox,
  Tooltip,
  ListItemButton,
  ListItemText,
  List,
  ListSubheader,
  Card,
  CardHeader,
  ListItem,
} from "@mui/material";

import { connect } from "react-redux";

import {
  getTempPSYQuestions,
  deleteAllTempPSYQuestions,
  deleteTempPSYQuestion,
  confirmPSYQuestions,
  uploadPSYQuestions,
} from "../../../store/psychometric/actions";

import { downloadActionExamQuestionTemplate } from "../../../store/recruitment/actions";

import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import { totalSum } from "../../common/components/Utils";
import NoRecordMessage from "../../common/components/NoRecordMessage";
import PreLoader from "../../common/components/PreLoader";
import ActionDialog from "../../common/components/ActionDialog";
import AlertDialog from "../../common/components/AlertDialog";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import { showError } from "../../toastify";
import readXlsxFile from "read-excel-file";
import ErrorMessage from "../../common/components/ErrorMessage";
import ReactPaginate from "react-paginate";
import AddOrUpdateQuestionDialog from "../components/AddOrUpdateQuestionDialog";
import { Link, useLocation, useHistory } from "react-router-dom";

import VerifiedRoundedIcon from "@mui/icons-material/VerifiedRounded";

const defaultQuestionOption = [
  {
    id: "",
    optionNumber: "A",
    en: "",
    fr: "",
    kn: "",
    matchEn: "",
    matchFr: "",
    matchKn: "",
    rateValue: 0,
    isCorrect: false,
  },
  {
    id: "",
    optionNumber: "B",
    en: "",
    fr: "",
    kn: "",
    matchEn: "",
    matchFr: "",
    matchKn: "",
    rateValue: 0,
    isCorrect: false,
  },
  {
    id: "",
    optionNumber: "C",
    en: "",
    fr: "",
    kn: "",
    matchEn: "",
    matchFr: "",
    matchKn: "",
    rateValue: 0,
    isCorrect: false,
  },
  {
    id: "",
    optionNumber: "D",
    en: "",
    fr: "",
    kn: "",
    matchEn: "",
    matchFr: "",
    matchKn: "",
    rateValue: 0,
    isCorrect: false,
  },
];

const defaultQuestion = {
  id: "",
  en: "",
  fr: "",
  kn: "",
  questionTimeInSec: 0,
  score: 0,
  questionTypeId: 1,
  knowledgeLevelId: "",
  knowledgeSubDomainId: "",
  options: defaultQuestionOption,
  questionImage: "",
  actionType: "CREATE",
};

const TempQuestions = (props) => {
  const {
    user,
    showDialog,
    setShowDialog,
    loading,
    getTempPSYQuestions,
    tempPSYQuestions,
    deleteAllTempPSYQuestions,
    confirmPSYQuestions,
    setShowUploadWrittenExamQuestionsForm,
    selectedAdvertisement,
    uploadPSYQuestions,
    downloadActionExamQuestionTemplate,
    questionTypes,
    knowledgeLevels,
    knowledgeSubDomains,
    setTotalRequests,
    deleteTempPSYQuestion,
  } = props;

  const history = useHistory();

  const [question, setQuestion] = useState(defaultQuestion);

  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [showEditQuestion, setShowEditQuestion] = useState(false);
  const [showUploadExamForm, setShowUploadExamForm] = useState(false);
  const [confirmRemoveAll, setConfirmRemoveAll] = useState(false);
  const [confirmUploadAll, setConfirmUploadAll] = useState(false);

  const [isConfirming, setIsConfirming] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const [errors, setErrors] = useState([]);

  useEffect(() => {
    getTempPSYQuestions();
  }, []);

  const onClose = () => {
    setShowDialog(false);
  };

  //PAGINATION
  const [paginatedItems, setPaginatedItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 5;

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    setPaginatedItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  };

  useEffect(() => {
    paginate(tempPSYQuestions);
  }, [itemOffset, itemsPerPage, tempPSYQuestions]);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % tempPSYQuestions.length || 0;

    setItemOffset(newOffset);
  };

  const getNo = (data, key, idToSearch) =>
    data.findIndex((item) => item[key] === idToSearch);

  const [showAddQuestionDialog, setShowAddQuestionDialog] = useState(false);

  const { hasPermissions } = useRequiredAuth();

  const [confirmed, setConfirmed] = useState("");

  return (
    <>
      <div className="bg-light p-2 border">
        <div className="d-flex" style={{ justifyContent: "space-between" }}>
          <div>
            <p className="mb-0 font-weight-bold">
              New Psychometric Test Questions
            </p>
            <p style={{ fontSize: 12 }}>Only excel files can be uploaded</p>
          </div>

          {hasPermissions([
            "IS_QUESTION_BANK_CREATOR",
            "IS_QUESTION_BANK_ADMIN",
          ]) && (
            <div>
              {hasPermissions([
                "IS_QUESTION_BANK_CREATOR",
                "IS_QUESTION_BANK_ADMIN",
              ]) && (
                <Button
                  size="small"
                  startIcon={
                    <span className="material-icons">delete_forever</span>
                  }
                  variant="contained"
                  color="error"
                  disabled={loading || !tempPSYQuestions.length}
                  onClick={() => setConfirmRemoveAll(true)}
                  type="button"
                  className="mx-2"
                >
                  Delete All
                </Button>
              )}

              {hasPermissions(["IS_QUESTION_BANK_CREATOR"]) && (
                <>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={() => setShowUploadExamForm(true)}
                    type="button"
                    // className="px-5"
                    disabled={loading}
                  >
                    <span className="material-icons">file_upload</span> Upload
                  </Button>
                  <Button
                    // disabled
                    onClick={() => setShowAddQuestionDialog(true)}
                    variant="outlined"
                    color="success"
                    size="small"
                    disableElevation
                    className="ml-2 "
                  >
                    <span className="material-icons">add</span>
                    <span>New question</span>
                  </Button>
                </>
              )}
            </div>
          )}
        </div>
        <div className="d-flex justify-content-between">
          <div className="d-flex justify-content-start">
            <strong>Total: {tempPSYQuestions.length} New Questions</strong>
            {hasPermissions(["IS_QUESTION_BANK_ADMIN"]) && (
              <div className="ml-2">
                <Button
                  size="small"
                  startIcon={<i className="fas fa-check"></i>}
                  variant="contained"
                  color="success"
                  onClick={() => {
                    setConfirmed(true);
                    setConfirmUploadAll(true);
                  }}
                  type="button"
                  disabled={
                    loading ||
                    !tempPSYQuestions.filter(
                      ({ actionType, statusId }) =>
                        actionType !== "EDIT" || statusId === 2
                    )?.length
                  }
                >
                  Confirm All
                </Button>
                <Button
                  size="small"
                  startIcon={<i className="fas fa-trash"></i>}
                  variant="contained"
                  color="error"
                  disabled={
                    loading ||
                    !tempPSYQuestions.find(({ statusId }) => statusId !== 3) ||
                    !tempPSYQuestions.filter(
                      ({ actionType, statusId }) =>
                        actionType !== "EDIT" || statusId === 2
                    )?.length
                  }
                  onClick={() => {
                    setConfirmed(false);
                    setConfirmUploadAll(true);
                  }}
                  type="button"
                  className=" mx-2"
                >
                  Reject All
                </Button>
                {/* {hasPermissions(["IS_QUESTION_BANK_ADMIN"]) && (
                  <Link to="/recruitment/psychometric/bank">
                    <Button
                      variant="outlined"
                      size="small"
                      disableElevation
                      className="mr-2 "
                    >
                      <span>Question Bank</span>
                    </Button>
                  </Link>
                )} */}
              </div>
            )}
          </div>
          {hasPermissions([
            "IS_QUESTION_BANK_ADMIN",
            "IS_QUESTION_BANK_CREATOR",
          ]) && (
            <div>
              <Button
                size="small"
                variant="text"
                color="primary"
                onClick={() =>
                  downloadActionExamQuestionTemplate("Psychometric_Questions")
                }
                type="button"
                disabled={loading}
              >
                <span className="material-icons">download</span> Download
                Template
              </Button>
            </div>
          )}
        </div>

        {/* {!!paginatedItems?.length && ( */}
        <table className="table table-striped table-sm  mt-2">
          <thead>
            <tr style={{ backgroundColor: "#f0f2f5" }}>
              <th scope="col">#</th>

              <th scope="col" className="text-left">
                ActionType
              </th>
              <th scope="col" className="text-left">
                Description
              </th>

              <th scope="col" className="text-left">
                Assertions
              </th>

              <th scope="col" className="text-left">
                Question Type
              </th>

              <th scope="col" className="text-left">
                Knowledge Level
              </th>

              <th scope="col" className="text-left">
                Knowledge Domain
              </th>

              <th scope="col" className="text-center">
                Seconds
              </th>

              <th scope="col" className="text-center">
                Score
              </th>

              <th scope="col" className="text-center">
                Actions
              </th>
            </tr>
          </thead>

          <tbody>
            {paginatedItems?.map((tempQuestion, index) => (
              <QuestionCard
                loading={loading}
                question={tempQuestion}
                index={getNo(tempPSYQuestions, "id", tempQuestion.id)}
                key={tempQuestion.id}
                setShowEditQuestion={setShowEditQuestion}
                setSelectedQuestion={setSelectedQuestion}
                setShowAddQuestionDialog={setShowAddQuestionDialog}
                setQuestion={setQuestion}
                hasPermissions={hasPermissions}
                deleteTempPSYQuestion={deleteTempPSYQuestion}
                length={tempPSYQuestions.length}
                confirmPSYQuestions={confirmPSYQuestions}
                history={history}
                user={user}
              />
            ))}
          </tbody>
        </table>
        {/* )} */}
        <div className="mt-2">
          <NoRecordMessage
            isEmpty={!paginatedItems?.length}
            title="No record found. "
          />
        </div>
        {confirmUploadAll && (
          <ConfirmationDialog
            confirmationDialog={confirmUploadAll}
            message={
              <>
                Are you sure you want to{" "}
                <strong className={`text-${confirmed ? "success" : "danger"}`}>
                  {confirmed ? "confirm" : "reject"}
                </strong>{" "}
                all question?
              </>
            }
            setConfirmationDialog={setConfirmUploadAll}
            onYes={() => {
              confirmPSYQuestions(confirmed, history, () =>
                setConfirmUploadAll(false)
              );
            }}
          />
        )}
        {confirmRemoveAll && (
          <ConfirmationDialog
            confirmationDialog={confirmRemoveAll}
            message={`Are you sure you want to remove all questions?`}
            setConfirmationDialog={setConfirmRemoveAll}
            onYes={() => {
              deleteAllTempPSYQuestions(setConfirmRemoveAll);
            }}
          />
        )}
        {showUploadExamForm && (
          <UploadExamDialog
            showUploadExamForm={showUploadExamForm}
            setShowUploadExamForm={setShowUploadExamForm}
            uploadPSYQuestions={uploadPSYQuestions}
            questionTypes={questionTypes}
            knowledgeLevels={knowledgeLevels}
            knowledgeSubDomains={knowledgeSubDomains}
            loading={loading}
            user={user}
          />
        )}
        {/* {showEditQuestion && (
<EditUploadedExamDialog
question={selectedQuestion}
showEditQuestion={showEditQuestion}
setShowEditQuestion={setShowEditQuestion}
/>
)} */}

        {showAddQuestionDialog && (
          <AddOrUpdateQuestionDialog
            showDialog={showAddQuestionDialog}
            setShowDialog={setShowAddQuestionDialog}
            question={question}
            setQuestion={setQuestion}
            defaultQuestionOption={defaultQuestionOption}
            defaultQuestion={defaultQuestion}
            isTemp={true}
          />
        )}
      </div>
      <ReactPaginate
        breakLabel="..."
        nextLabel={
          <>
            <span className="pr-1 d-none d-md-inline">Next</span>
            <i className="fas fa-angle-double-right"></i>
          </>
        }
        previousLabel={
          <>
            <i className="fas fa-angle-double-left"></i>
            <span className="pl-1  d-none d-md-inline">Previous</span>
          </>
        }
        pageRangeDisplayed={3}
        marginPagesDisplayed={1}
        onPageChange={handlePageClick}
        pageCount={pageCount}
        renderOnZeroPageCount={null}
        containerClassName="pagination"
        pageLinkClassName="btn btn-outline-dark btn-sm mr-1 ml-1"
        previousLinkClassName="btn btn-outline-dark btn-sm mr-1 ml-1"
        nextLinkClassName="btn btn-outline-dark btn-sm ml-1"
        activeLinkClassName="active"
      />
    </>
  );
};

const mapStateToProps = ({
  user,
  loading,
  tempPSYQuestions,
  questionTypes,
  knowledgeLevels,
  knowledgeSubDomains,
}) => {
  return {
    user,
    loading,
    tempPSYQuestions,
    questionTypes,
    knowledgeLevels,
    knowledgeSubDomains,
  };
};
export default connect(mapStateToProps, {
  getTempPSYQuestions,
  deleteAllTempPSYQuestions,
  deleteTempPSYQuestion,
  confirmPSYQuestions,
  uploadPSYQuestions,
  downloadActionExamQuestionTemplate,
})(TempQuestions);

const QuestionCard = (props) => {
  const {
    user,
    loading,
    question,
    index,
    setShowEditQuestion,
    setSelectedQuestion,
    setShowAddQuestionDialog,
    setQuestion,
    hasPermissions,
    deleteTempPSYQuestion,
    length,
    confirmPSYQuestions,
    history,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const onEditQuestion = async (handleCloseMenu) => {
    const tmpQuestion = {
      id: question.id,
      en: question.en,
      fr: question.fr,
      kn: question.kn,
      questionTimeInSec: question.questionTimeInSec,
      questionTypeId: question.questionTypeId,
      knowledgeLevelId: question.knowledgeLevelId,
      knowledgeSubDomainId: question.knowledgeSubDomainId,
      score: question.score,
      questionImage: question.questionImage || "",
      actionType: question.actionType,
      options: [
        {
          id: "",
          optionNumber: "A",
          en: (question.enOptionA || "").replace("*", ""),
          fr: (question.frOptionA || "").replace("*", ""),
          kn: (question.knOptionA || "").replace("*", ""),
          matchEn: question.enMatchA || "",
          matchFr: question.frMatchA || "",
          matchKn: question.knMatchA || "",
          isCorrect: (question.enOptionA || "").includes("*") ? true : false,
        },
        {
          id: "",
          optionNumber: "B",
          en: (question.enOptionB || "").replace("*", ""),
          fr: (question.frOptionB || "").replace("*", ""),
          kn: (question.knOptionB || "").replace("*", ""),
          matchEn: question.enMatchB || "",
          matchFr: question.frMatchB || "",
          matchKn: question.knMatchB || "",
          isCorrect: (question.enOptionB || "").includes("*") ? true : false,
        },
        {
          id: "",
          optionNumber: "C",
          en: (question.enOptionC || "").replace("*", ""),
          fr: (question.frOptionC || "").replace("*", ""),
          kn: (question.knOptionC || "").replace("*", ""),
          matchEn: question.enMatchC || "",
          matchFr: question.frMatchC || "",
          matchKn: question.knMatchC || "",
          isCorrect: (question.enOptionC || "").includes("*") ? true : false,
        },
        {
          id: "",
          optionNumber: "D",
          en: (question.enOptionD || "").replace("*", ""),
          fr: (question.frOptionD || "").replace("*", ""),
          kn: (question.knOptionD || "").replace("*", ""),
          matchEn: question.enMatchD || "",
          matchFr: question.frMatchD || "",
          matchKn: question.knMatchD || "",
          isCorrect: (question.enOptionD || "").includes("*") ? true : false,
        },
      ],
    };

    setQuestion(tmpQuestion);
    handleCloseMenu();
    setShowAddQuestionDialog(true);
  };

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [confirmed, setConfirmed] = useState("");

  return (
    <>
      <tr>
        <th
          scope="row"
          style={{
            backgroundColor:
              question.actionType === "EDIT" ? "#0081da75" : "#d4dde7",
          }}
        >
          {length - index}
        </th>
        <th
          scope="row"
          style={{
            backgroundColor:
              question.actionType === "EDIT" ? "#0081da75" : "#d4dde7",
          }}
        >
          {question.actionType}
        </th>

        <td
          className="text-left"
          style={{
            width: "40%",
          }}
        >
          <strong>EN:</strong> {question.en} <br />
          <strong>FR:</strong> {question.fr} <br />
          <strong>KN:</strong> {question.kn}
        </td>
        <td
          className="text-left"
          style={{
            width: "30%",
          }}
        >
          <table>
            <tbody>
              {question && question.enOptionA && (
                <tr>
                  <td>A:</td>
                  <td>
                    <p>
                      <strong>EN:</strong> {question.enOptionA} <br />
                      <strong>FR:</strong> {question.frOptionA} <br />
                      <strong>KN:</strong> {question.knOptionA}
                      {question.enOptionA.includes("*") && (
                        <span className="badge badge-success ml-1">
                          {" "}
                          Correct
                        </span>
                      )}
                    </p>
                  </td>
                </tr>
              )}

              {question && question.enOptionB && (
                <tr>
                  <td>B:</td>
                  <td>
                    <p>
                      <strong>EN:</strong> {question.enOptionB} <br />
                      <strong>FR:</strong> {question.frOptionB} <br />
                      <strong>KN:</strong> {question.knOptionB}
                      {question.enOptionB.includes("*") && (
                        <span className="badge badge-success ml-1">
                          {" "}
                          Correct
                        </span>
                      )}
                    </p>
                  </td>
                </tr>
              )}
              {question && question.enOptionC && (
                <tr>
                  <td>C:</td>
                  <td>
                    <p>
                      <strong>EN:</strong> {question.enOptionC} <br />
                      <strong>FR:</strong> {question.frOptionC} <br />
                      <strong>KN:</strong> {question.knOptionC}
                      {question.enOptionC.includes("*") && (
                        <span className="badge badge-success ml-1">
                          {" "}
                          Correct
                        </span>
                      )}
                    </p>
                  </td>
                </tr>
              )}

              {question && question.enOptionD && (
                <tr>
                  <td>D:</td>
                  <td>
                    <p>
                      <strong>EN:</strong> {question.enOptionD} <br />
                      <strong>FR:</strong> {question.frOptionD} <br />
                      <strong>KN:</strong> {question.knOptionD}
                      {question.enOptionD.includes("*") && (
                        <span className="badge badge-success ml-1">
                          {" "}
                          Correct
                        </span>
                      )}
                    </p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </td>
        <td className="text-left">{question.questionTypeName}</td>

        <td className="text-left">{question.knowledgeLevelName}</td>

        <td className="text-left">{question.knowledgeSubDomainName}</td>

        <td className="text-center">{question.score} Marks</td>

        <td className="text-center">{question.questionTimeInSec} Sec</td>

        <td className="text-center">
          <span className="d-flex justify-content-end align-items-center">
            <span className="d-flex flex-column align-items-center">
              <span
                className={`badge badge-${
                  question.statusId === 3
                    ? "danger"
                    : question.statusId === 2
                    ? "primary"
                    : "secondary"
                }`}
              >
                {question.statusName}
              </span>
              {question.actionType === "EDIT" && (
                <span className="text-primary">
                  {question.assignedToUserName || "Editor"}
                </span>
              )}
            </span>

            <span>
              <IconButton
                size="small"
                onClick={handleOpenMenu}
                //  onMouseMove={handleOpenMenu}
                aria-label="settings"
              >
                <Badge color="info">
                  <span className="material-icons">more_vert</span>
                </Badge>
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleCloseMenu}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    // maxHeight: ITEM_HEIGHT * 4.5,
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 2,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 15,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                {hasPermissions(["IS_QUESTION_BANK_ADMIN"]) && (
                  <span>
                    <MenuItem
                      disabled={
                        loading ||
                        (question.actionType === "EDIT" &&
                          question.statusId === 1)
                      }
                      // disabled={loading}
                      onClick={() => {
                        setConfirmed(true);
                        handleCloseMenu();
                        setShowConfirmDialog(true);
                      }}
                      className="text-success font-weight-bold"
                    >
                      <span className="material-icons mr-2">done</span> Confirm
                    </MenuItem>
                  </span>
                )}
                {hasPermissions(["IS_QUESTION_BANK_ADMIN"]) && (
                  <span>
                    <Divider className="my-1" />
                    <MenuItem
                      disabled={
                        loading ||
                        question?.statusId === 3 ||
                        (question.actionType === "EDIT" &&
                          question.statusId === 1)
                      }
                      onClick={() => {
                        setConfirmed(false);
                        handleCloseMenu();
                        setShowConfirmDialog(true);
                      }}
                      className="text-danger "
                    >
                      <span className="material-icons mr-2">close</span>
                      Reject
                    </MenuItem>
                  </span>
                )}
                {hasPermissions([
                  "IS_QUESTION_BANK_CREATOR",
                  "IS_QUESTION_BANK_EDITOR",
                  "IS_QUESTION_BANK_ADMIN",
                ]) && (
                  <span>
                    <MenuItem
                      disabled={
                        loading ||
                        (question.actionType === "EDIT" &&
                          question.assignedToUserId !== user.id)
                      }
                      onClick={() => {
                        onEditQuestion(handleCloseMenu);
                      }}
                      className="text-primary font-weight-bold"
                    >
                      <span className="material-icons mr-2">edit_note</span>{" "}
                      Edit
                    </MenuItem>
                  </span>
                )}

                {hasPermissions([
                  "IS_QUESTION_BANK_CREATOR",
                  "IS_QUESTION_BANK_ADMIN",
                ]) && (
                  <span>
                    <Divider className="my-1" />
                    <MenuItem
                      disabled={loading}
                      onClick={() => {
                        setConfirmDelete(true);
                        handleCloseMenu();
                      }}
                      className="text-danger "
                    >
                      <span className="material-icons mr-2">
                        delete_forever
                      </span>
                      Delete
                    </MenuItem>
                  </span>
                )}
              </Menu>
            </span>
          </span>
        </td>
      </tr>

      {confirmDelete && (
        <ConfirmationDialog
          confirmationDialog={confirmDelete}
          message={
            <>
              Are you sure you want to{" "}
              <strong className={`text-danger`}>delete</strong> this quesition?
            </>
          }
          setConfirmationDialog={setConfirmDelete}
          onYes={() => {
            deleteTempPSYQuestion(question.id, setConfirmDelete);
          }}
        />
      )}

      {showConfirmDialog && (
        <ConfirmationDialog
          confirmationDialog={showConfirmDialog}
          message={
            <>
              Are you sure you want to{" "}
              <strong className={`text-${confirmed ? "success" : "danger"}`}>
                {confirmed ? "confirm" : "reject"}
              </strong>{" "}
              this question?
            </>
          }
          setConfirmationDialog={setShowConfirmDialog}
          onYes={() => {
            confirmPSYQuestions(
              confirmed,
              history,
              () => setShowConfirmDialog(false),
              question.id
            );
          }}
        />
      )}
    </>
  );
};

const UploadExamDialog = (props) => {
  const {
    user,
    loading,
    uploadPSYQuestions,
    showUploadExamForm,
    setShowUploadExamForm,

    questionTypes,
    knowledgeLevels,
    knowledgeSubDomains,
  } = props;

  const [examFile, setExamFile] = useState("");

  const [questions, setQuestions] = useState([]);
  const [uploadErrors, setUploadErrors] = useState([]);

  useEffect(() => {
    if (!!examFile) uploadFile();
  }, [examFile]);

  const [errors, setErrors] = useState({
    fileHasError: false,
    hasError: false,
  });

  const convertToLower = (value) => {
    return value.toLowerCase();
  };
  const isFileTooLarge = (file) => {
    const size = file?.size;
    return size > 1074176 ? true : false;
  };

  const handleUploadedFile = (event) => {
    let file = event?.target?.files[0];
    setUploadErrors([]);
    if (
      !!file &&
      (convertToLower(file?.name.substr(-4)) === ".xls" ||
        convertToLower(file?.name.substr(-5)) === ".xlsx") &&
      !isFileTooLarge(file)
    ) {
      setExamFile(file);
      setErrors({ ...errors, fileHasError: false });
    } else if (isFileTooLarge(file)) {
      file = "";
      setExamFile("");
      showError("Allowed file should not exceed 3MB");
    } else {
      showError("Provide excel file");
    }
  };

  const onSave = async () => {
    if (!!questions.length) uploadPSYQuestions(questions, onClose);
  };

  const onClose = () => {
    setShowUploadExamForm(false);
  };

  const uploadFile = async () => {
    await readXlsxFile(examFile).then((rows) => {
      let tempQuestions = [];
      const tempErrors = [];

      if (!!rows.length) {
        const header = rows[0];

        if (
          header &&
          header[0] &&
          header[0].toLowerCase().includes("no") &&
          header[1] &&
          header[1].toLowerCase().includes("questiontype") &&
          header[2] &&
          header[2].toLowerCase().includes("knowledgelevel") &&
          header[3] &&
          header[3].toLowerCase().includes("knowledgedomain") &&
          header[4] &&
          header[4].toLowerCase().includes("marks") &&
          header[5] &&
          header[5].toLowerCase().includes("seconds") &&
          header[6] &&
          header[6].toLowerCase().includes("enquestion") &&
          header[7] &&
          header[7].toLowerCase().includes("frquestion") &&
          header[8] &&
          header[8].toLowerCase().includes("knquestion") &&
          header[9] &&
          header[9].toLowerCase().includes("ena") &&
          header[10] &&
          header[10].toLowerCase().includes("fra") &&
          header[11] &&
          header[11].toLowerCase().includes("kna") &&
          header[12] &&
          header[12].toLowerCase().includes("enb") &&
          header[13] &&
          header[13].toLowerCase().includes("frb") &&
          header[14] &&
          header[14].toLowerCase().includes("knb") &&
          header[15] &&
          header[15].toLowerCase().includes("enc") &&
          header[16] &&
          header[16].toLowerCase().includes("frc") &&
          header[17] &&
          header[17].toLowerCase().includes("knc") &&
          header[18] &&
          header[18].toLowerCase().includes("end") &&
          header[19] &&
          header[19].toLowerCase().includes("frd") &&
          header[20] &&
          header[20].toLowerCase().includes("knd") &&
          header[21] &&
          header[21].toLowerCase().includes("enmatcha") &&
          header[22] &&
          header[22].toLowerCase().includes("frmatcha") &&
          header[23] &&
          header[23].toLowerCase().includes("knmatcha") &&
          header[24] &&
          header[24].toLowerCase().includes("enmatchb") &&
          header[25] &&
          header[25].toLowerCase().includes("frmatchb") &&
          header[26] &&
          header[26].toLowerCase().includes("knmatchb") &&
          header[27] &&
          header[27].toLowerCase().includes("enmatchc") &&
          header[28] &&
          header[28].toLowerCase().includes("frmatchc") &&
          header[29] &&
          header[29].toLowerCase().includes("knmatchc") &&
          header[30] &&
          header[30].toLowerCase().includes("enmatchd") &&
          header[31] &&
          header[31].toLowerCase().includes("frmatchd") &&
          header[32] &&
          header[32].toLowerCase().includes("knmatchd")
        ) {
          rows.shift();

          rows.forEach((cols) => {
            let question = {
              questionTypeId:
                questionTypes.find(
                  ({ name }) =>
                    name.toLowerCase().replace(/ /g, "") ===
                    (cols[1] && cols[1] !== "" ? cols[1].toString().trim() : "")
                      .toLowerCase()
                      .replace(/ /g, "")
                )?.id || null,
              knowledgeLevelId:
                knowledgeLevels.find(
                  ({ name }) =>
                    name.toLowerCase().replace(/ /g, "") ===
                    (cols[2] && cols[2] !== "" ? cols[2].toString().trim() : "")
                      .toLowerCase()
                      .replace(/ /g, "")
                )?.id || null,
              knowledgeSubDomainId:
                knowledgeSubDomains.find(
                  ({ name }) =>
                    name.toLowerCase().replace(/ /g, "") ===
                    (cols[3] && cols[3] !== "" ? cols[3].toString().trim() : "")
                      .toLowerCase()
                      .replace(/ /g, "")
                )?.id || null,
              score: cols[4] && cols[4] !== "" ? parseInt(cols[4]) : "",
              questionTimeInSec:
                cols[5] && cols[5] !== "" ? parseInt(cols[5]) : "",

              en: cols[6] && cols[6] !== "" ? cols[6].toString().trim() : "",
              fr: cols[7] && cols[7] !== "" ? cols[7].toString().trim() : "",
              kn: cols[8] && cols[8] !== "" ? cols[8].toString().trim() : "",
              enOptionA:
                cols[9] && cols[9] !== "" ? cols[9].toString().trim() : "",
              frOptionA:
                cols[10] && cols[10] !== "" ? cols[10].toString().trim() : "",
              knOptionA:
                cols[11] && cols[11] !== "" ? cols[11].toString().trim() : "",
              enOptionB:
                cols[12] && cols[12] !== "" ? cols[12].toString().trim() : "",
              frOptionB:
                cols[13] && cols[13] !== "" ? cols[13].toString().trim() : "",
              knOptionB:
                cols[14] && cols[14] !== "" ? cols[14].toString().trim() : "",
              enOptionC:
                cols[15] && cols[15] !== "" ? cols[15].toString().trim() : "",
              frOptionC:
                cols[16] && cols[16] !== "" ? cols[16].toString().trim() : "",
              knOptionC:
                cols[17] && cols[17] !== "" ? cols[17].toString().trim() : "",
              enOptionD:
                cols[18] && cols[18] !== "" ? cols[18].toString().trim() : "",
              frOptionD:
                cols[19] && cols[19] !== "" ? cols[19].toString().trim() : "",
              knOptionD:
                cols[20] && cols[20] !== "" ? cols[20].toString().trim() : "",
              enMatchA:
                cols[21] && cols[21] !== "" ? cols[21].toString().trim() : "",
              frMatchA:
                cols[22] && cols[22] !== "" ? cols[22].toString().trim() : "",
              knMatchA:
                cols[23] && cols[23] !== "" ? cols[23].toString().trim() : "",
              enMatchB:
                cols[24] && cols[24] !== "" ? cols[24].toString().trim() : "",
              frMatchB:
                cols[25] && cols[25] !== "" ? cols[25].toString().trim() : "",
              knMatchB:
                cols[26] && cols[26] !== "" ? cols[26].toString().trim() : "",
              enMatchC:
                cols[27] && cols[27] !== "" ? cols[27].toString().trim() : "",
              frMatchC:
                cols[28] && cols[28] !== "" ? cols[28].toString().trim() : "",
              knMatchC:
                cols[29] && cols[29] !== "" ? cols[29].toString().trim() : "",
              enMatchD:
                cols[30] && cols[30] !== "" ? cols[30].toString().trim() : "",
              frMatchD:
                cols[31] && cols[31] !== "" ? cols[31].toString().trim() : "",
              knMatchD:
                cols[32] && cols[32] !== "" ? cols[32].toString().trim() : "",

              createdBy: user.id,
              actionType: "CREATE",
            };

            tempQuestions.push(question);
          });
        } else {
          tempErrors.push("Invalid excel file");
        }
      } else {
        tempErrors.push("Empty file");
      }

      tempQuestions.forEach((question, index) => {
        let questionErrors = "";
        let questionErrorCount = 0;
        let rightAnswers = 0;

        const {
          questionTypeId,
          knowledgeLevelId,
          knowledgeSubDomainId,
          score,
          questionTimeInSec,
          en,
          fr,
          kn,
          enOptionA,
          frOptionA,
          knOptionA,
          enOptionB,
          frOptionB,
          knOptionB,
          enOptionC,
          frOptionC,
          knOptionC,
          enOptionD,
          frOptionD,
          knOptionD,
          enMatchA,
          frMatchA,
          knMatchA,
          enMatchB,
          frMatchB,
          knMatchB,
          enMatchC,
          frMatchC,
          knMatchC,
          enMatchD,
          frMatchD,
          knMatchD,
        } = question;

        if (!questionTypeId) {
          questionErrorCount++;
          questionErrors += `${questionErrorCount}:Invalid QuestionType, `;
        }
        if (!knowledgeLevelId) {
          questionErrorCount++;
          questionErrors += `${questionErrorCount}:Invalid KnowledgeLevel, `;
        }

        if (!knowledgeSubDomainId) {
          questionErrorCount++;
          questionErrors += `${questionErrorCount}:Invalid KnowledgeDomain, `;
        }

        if (!score) {
          questionErrorCount++;
          questionErrors += `${questionErrorCount}:Marks was not set, `;
        }

        if (!questionTimeInSec) {
          questionErrorCount++;
          questionErrors += `${questionErrorCount}:Seconds was not set, `;
        }

        if (!en && !fr && !kn) {
          questionErrorCount++;
          questionErrors += `${questionErrorCount}:Question description is missing, `;
        }

        if (
          !enOptionA &&
          !frOptionA &&
          !knOptionA &&
          ![2].includes(questionTypeId)
        ) {
          questionErrorCount++;
          questionErrors += `${questionErrorCount}:OptionA is missing, `;
        }
        if (enOptionA.includes("*") && ![5].includes(questionTypeId))
          rightAnswers++;

        if (
          !enOptionB &&
          !frOptionB &&
          !knOptionB &&
          ![2].includes(questionTypeId)
        ) {
          questionErrorCount++;
          questionErrors += `${questionErrorCount}:OptionB is missing, `;
        }
        if (enOptionB.includes("*") && ![5].includes(questionTypeId))
          rightAnswers++;

        if (
          !enOptionC &&
          !knOptionC &&
          !frOptionC &&
          ![2, 4].includes(questionTypeId)
        ) {
          questionErrorCount++;
          questionErrors += `${questionErrorCount}:OptionC is missing, `;
        }
        if (enOptionC.includes("*") && ![2, 5].includes(questionTypeId))
          rightAnswers++;

        if (
          !enOptionD &&
          !frOptionD &&
          !knOptionD &&
          ![2, 4].includes(questionTypeId)
        ) {
          questionErrorCount++;
          questionErrors += `${questionErrorCount}:OptionD is missing, `;
        }
        if (enOptionD.includes("*") && ![2, 5].includes(questionTypeId))
          rightAnswers++;

        if (
          !enMatchA &&
          !frMatchA &&
          !knMatchA &&
          [5].includes(questionTypeId)
        ) {
          questionErrorCount++;
          questionErrors += `${questionErrorCount}:MatchOptionA is missing, `;
        }

        if (
          !enMatchB &&
          !frMatchB &&
          !knMatchB &&
          [5].includes(questionTypeId)
        ) {
          questionErrorCount++;
          questionErrors += `${questionErrorCount}:MatchOptionB is missing, `;
        }

        if (
          !enMatchC &&
          !frMatchC &&
          !knMatchC &&
          [5].includes(questionTypeId)
        ) {
          questionErrorCount++;
          questionErrors += `${questionErrorCount}:MatchOptionC is missing, `;
        }

        if (
          !enMatchD &&
          !frMatchD &&
          !knMatchD &&
          [5].includes(questionTypeId)
        ) {
          questionErrorCount++;
          questionErrors += `${questionErrorCount}:MatchOptionD is missing, `;
        }

        if (rightAnswers === 0 && ![5].includes(questionTypeId)) {
          questionErrorCount++;
          questionErrors += `${questionErrorCount}:No right answer, `;
        }

        if (rightAnswers > 1) {
          questionErrorCount++;
          questionErrors += `${questionErrorCount}:Too many right answers (${rightAnswers}), `;
        }

        if (!!questionErrors) {
          // console.log(question);
          tempErrors.push(
            `Q.${index + 1} (${questionErrorCount} Error${
              questionErrorCount > 1 ? "s" : ""
            })=> ${questionErrors.slice(0, -2)}`
          );
        }
      });

      setUploadErrors([...tempErrors]);
      setQuestions(tempQuestions);
    });
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showUploadExamForm}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle className="text-primary">
          <Typography variant="h6" noWrap component="div">
            <span className="text-dark">Upload questions</span>
          </Typography>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <div
            style={{
              minHeight: "25vh",
            }}
          >
            <div className="row">
              <div className="col-12 mt-3">
                Upload Questions(* only excel file)
                <div className="input-group mb-1">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fas fa-paperclip"></i>
                    </span>
                  </div>

                  <input
                    type="file"
                    name="file"
                    className="form-control"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    placeholder="Select excel file"
                    onChange={(e) => handleUploadedFile(e)}
                  />
                </div>
              </div>

              {!uploadErrors.length && !!questions.length && (
                <div className="col-12">
                  <Typography className="alert alert-success text-center">
                    <VerifiedRoundedIcon />
                    <strong>{questions.length} questions</strong> successful
                    verified!
                  </Typography>
                </div>
              )}

              {!!uploadErrors.length && (
                <div className="col-12">
                  <List
                    disablePadding
                    dense
                    sx={{
                      width: "100%",
                      bgcolor: "background.paper",
                      borderRadius: "2px",
                      border: "1px solid red",
                    }}
                    component="div"
                    subheader={
                      <div
                        className="text-danger px-3 bg-light"
                        style={{ borderBottom: "1px solid red" }}
                      >
                        ({uploadErrors.length}) Error
                        {uploadErrors.length > 1 && "s"}
                      </div>
                    }
                  >
                    {uploadErrors.map((error, index) => (
                      <ListItem
                        divider
                        disablePadding
                        key={index}
                        className="px-3"
                      >
                        <ListItemText
                          className="my-0"
                          primary={
                            <small className="text-danger">{error}</small>
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                </div>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center py-4">
          <Button
            variant="contained"
            color="primary"
            onClick={onSave}
            type="button"
            className="px-5"
            disabled={loading || !questions.length || !!uploadErrors.length}
          >
            Upload questions
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
