export const defaultState = {
  knowledgeDomains: [],
  knowledgeSubDomains: [],
  psychometricLanguages: [],
  positionPsychometricLanguages: [],
  positionKnowledgeSubDomains: [],

  questionBankQuestions: [],
  questionBankKnowledgeDomains: [],

  knowledgeLevels: [],

  tempPSYQuestions: [],

  liveCandidates: [],
  applicantSessionLogs: [],

  dashboardChartsData: {},

  requisitionPsychometricLanguages: [],
  requisitionKnowledgeSubDomains: [],
};
