import { defaultState } from "./state";
import structure from "./action-types";
import commonTypes from "../common/action-types";

const types = { ...commonTypes, ...structure };

const knowledgeDomains = (
  knowledgeDomainsState = defaultState.knowledgeDomains,
  action
) => {
  switch (action.type) {
    case types.SET_KNOWLEDGE_DOMAINS:
      return action.data;

    case types.ADD_OR_UPDATE_KNOWLEDGE_DOMAIN: {
      const tmpState = [...knowledgeDomainsState];

      const index = tmpState.findIndex(({ id }) => id === action.data.id);

      if (index >= 0) tmpState[index] = { ...action.data };
      else if (!!action.data.id) tmpState.unshift(action.data);

      return tmpState;
    }

    case types.CLEAN_STATE:
      return defaultState.knowledgeDomains;

    default:
      return knowledgeDomainsState;
  }
};

const knowledgeSubDomains = (
  knowledgeSubDomainsState = defaultState.knowledgeSubDomains,
  action
) => {
  switch (action.type) {
    case types.SET_KNOWLEDGE_SUB_DOMAINS:
      return action.data;

    case types.ADD_OR_UPDATE_KNOWLEDGE_SUB_DOMAIN: {
      const tmpState = [...knowledgeSubDomainsState];

      const index = tmpState.findIndex(({ id }) => id === action.data.id);

      if (index >= 0) tmpState[index] = { ...action.data };
      else if (!!action.data.id) tmpState.unshift(action.data);

      return tmpState;
    }

    case types.CLEAN_STATE:
      return defaultState.knowledgeSubDomains;

    default:
      return knowledgeSubDomainsState;
  }
};

const knowledgeLevels = (
  knowledgeLevelsState = defaultState.knowledgeLevels,
  action
) => {
  switch (action.type) {
    case types.SET_KNOWLEDGE_LEVELS:
      return action.data;

    case types.ADD_OR_UPDATE_KNOWLEDGE_LEVEL: {
      const tmpState = [...knowledgeLevelsState];

      const index = tmpState.findIndex(({ id }) => id === action.data.id);

      if (index >= 0) tmpState[index] = { ...action.data };
      else if (!!action.data.id) tmpState.unshift(action.data);

      return tmpState;
    }

    case types.CLEAN_STATE:
      return defaultState.knowledgeLevels;

    default:
      return knowledgeLevelsState;
  }
};

const psychometricLanguages = (
  psychometricLanguagesState = defaultState.psychometricLanguages,
  action
) => {
  switch (action.type) {
    case types.SET_PSYCHOMETRIC_LANGUAGES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.psychometricLanguages;

    default:
      return psychometricLanguagesState;
  }
};

const positionPsychometricLanguages = (
  positionPsychometricLanguagesState = defaultState.positionPsychometricLanguages,
  action
) => {
  switch (action.type) {
    case types.SET_POSITION_PSYCHOMETRIC_LANGUAGES:
      return action.data;

    case types.DELETE_POSITION_PSYCHOMETRIC_LANGUAGE: {
      const tmpState = [...positionPsychometricLanguagesState];

      const index = tmpState.findIndex(({ id }) => id === action.id);

      if (index >= 0) tmpState.splice(index, 1);

      return tmpState;
    }

    case types.SAVE_POSITION_PSYCHOMETRIC_LANGUAGE: {
      const tmpState = [...positionPsychometricLanguagesState];

      if (!!action?.data?.id) {
        tmpState.push(action.data);
        return tmpState;
      } else if (!!action?.data?.length) return action.data;
    }

    case types.CLEAN_STATE:
      return defaultState.positionPsychometricLanguages;

    default:
      return positionPsychometricLanguagesState;
  }
};

const positionKnowledgeSubDomains = (
  positionKnowledgeSubDomainsState = defaultState.positionKnowledgeSubDomains,
  action
) => {
  switch (action.type) {
    case types.SET_POSITION_KNOWLEDGE_SUBDOMAINS:
      return action.data;

    case types.DELETE_POSITION_KNOWLEDGE_SUBDOMAIN: {
      const tmpState = [...positionKnowledgeSubDomainsState];

      const index = tmpState.findIndex(({ id }) => id === action.id);

      if (index >= 0) tmpState.splice(index, 1);

      return tmpState;
    }

    case types.SAVE_POSITION_KNOWLEDGE_SUBDOMAIN: {
      const tmpState = [...positionKnowledgeSubDomainsState];

      if (!!action?.data?.id) {
        tmpState.push(action.data);
        return tmpState;
      } else if (!!action?.data?.length) return action.data;
    }

    case types.CLEAN_STATE:
      return defaultState.positionKnowledgeSubDomains;

    default:
      return positionKnowledgeSubDomainsState;
  }
};

const questionBankQuestions = (
  questionBankState = defaultState.questionBankQuestions,
  action
) => {
  switch (action.type) {
    case types.SET_QUESTIONBANK_QUESTIONS:
      return action.data;

    case types.ADD_OR_UPDATE_QUESTIONBANK_QUESTION: {
      const tmpState = [...questionBankState];

      if (!!action?.data?.id) {
        const index = tmpState.findIndex(({ id }) => id === action.data.id);

        if (index >= 0)
          tmpState[index] = { ...action.data, no: tmpState[index].no };
        else tmpState.unshift(action.data);
      }

      return tmpState;
    }

    case types.DELETE_QUESTIONBANK_QUESTION: {
      const tmpState = [...questionBankState];

      const index = tmpState.findIndex(({ id }) => id === action.id);

      if (index >= 0) tmpState.splice(index, 1);

      return tmpState;
    }

    case types.CLEAN_STATE:
      return defaultState.questionBankQuestions;

    default:
      return questionBankState;
  }
};

const questionBankKnowledgeDomains = (
  questionBankKnowledgeDomainsState = defaultState.questionBankKnowledgeDomains,
  action
) => {
  switch (action.type) {
    case types.SET_QUESTIONBANK_KNOWLEDGE_DOMAINS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.questionBankKnowledgeDomains;

    default:
      return questionBankKnowledgeDomainsState;
  }
};

const tempPSYQuestions = (
  tempPSYQuestionsState = defaultState.tempPSYQuestions,
  action
) => {
  switch (action.type) {
    case types.SET_TEMP_QUESTIONBANK_QUESTIONS:
      return action.data;

    case types.ADD_OR_UPDATE_TEMP_QUESTIONBANK_QUESTION: {
      const tmpState = [...tempPSYQuestionsState];

      if (!!action?.data?.id) {
        const index = tmpState.findIndex(({ id }) => id === action.data.id);

        if (index >= 0) tmpState[index] = action.data;
        else tmpState.unshift(action.data);
      }

      return tmpState;
    }

    case types.DELETE_TEMP_QUESTIONBANK_QUESTION: {
      const tmpState = [...tempPSYQuestionsState];

      const index = tmpState.findIndex(({ id }) => +id === +action.id);

      if (index >= 0) tmpState.splice(index, 1);

      return tmpState;
    }

    case types.CLEAN_STATE:
      return defaultState.tempPSYQuestions;

    default:
      return tempPSYQuestionsState;
  }
};

const liveCandidates = (
  liveCandidatesState = defaultState.liveCandidates,
  action
) => {
  switch (action.type) {
    case types.SET_LIVE_CANDIDATES:
      return action.data;

    case types.ADD_OR_UPDATE_LIVE_CANDIDATE: {
      const tmpState = [...liveCandidatesState];

      const index = tmpState.findIndex(({ id }) => id === action.data.id);

      if (index >= 0) tmpState[index] = { ...action.data };
      else if (!!action.data.id) tmpState.unshift(action.data);

      return tmpState;
    }

    case types.DELETE_LIVE_CANDIDATE: {
      const tmpState = [...liveCandidatesState];

      const index = tmpState.findIndex(({ id }) => id === action.data.id);

      if (index >= 0) tmpState.splice(index, 1);

      return tmpState;
    }

    case types.CLEAN_STATE:
      return defaultState.liveCandidates;

    default:
      return liveCandidatesState;
  }
};

const applicantSessionLogs = (
  applicantSessionLogsState = defaultState.applicantSessionLogs,
  action
) => {
  switch (action.type) {
    case types.SET_APPLICANT_SESSION_LOGS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.applicantSessionLogs;

    default:
      return applicantSessionLogsState;
  }
};

const dashboardChartsData = (
  dashboardChartsDataState = defaultState.dashboardChartsData,
  action
) => {
  switch (action.type) {
    case types.SET_DASHBOARD_CHART_DATA:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.dashboardChartsData;

    default:
      return dashboardChartsDataState;
  }
};

const requisitionPsychometricLanguages = (
  requisitionPsychometricLanguagesState = defaultState.requisitionPsychometricLanguages,
  action
) => {
  switch (action.type) {
    case types.SET_REQUISITION_PSYCHOMETRIC_LANGUAGES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.requisitionPsychometricLanguages;

    default:
      return requisitionPsychometricLanguagesState;
  }
};

const requisitionKnowledgeSubDomains = (
  requisitionKnowledgeSubDomainsState = defaultState.requisitionKnowledgeSubDomains,
  action
) => {
  switch (action.type) {
    case types.SET_REQUISITION_KNOWLEDGE_SUBDOMAINS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.requisitionKnowledgeSubDomains;

    default:
      return requisitionKnowledgeSubDomainsState;
  }
};

export default {
  knowledgeDomains,
  knowledgeSubDomains,
  knowledgeLevels,
  psychometricLanguages,
  positionPsychometricLanguages,
  positionKnowledgeSubDomains,

  questionBankQuestions,
  questionBankKnowledgeDomains,

  tempPSYQuestions,

  liveCandidates,
  applicantSessionLogs,

  dashboardChartsData,

  requisitionPsychometricLanguages,
  requisitionKnowledgeSubDomains,
};
